import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllCategories, fetchAllProjects} from '../redux/actions'
import clsx from 'clsx'

const FilterButton = ({setKeyword, tag = undefined, title}) => {
  let [active, setActive] = useState(false)
  let filterHandle = () => {
    setKeyword(tag)
    // setActive(true)
  }
  return (
    <button
      key={'all'}
      onClick={filterHandle}
      className={clsx(active && 'active', 'btnText mr-2 text-gray-400')}
    >
      {title}
    </button>
  )
}

export const FilterNav = () => {
  let [keyword, setKeyword] = useState('')
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllProjects(keyword))
  }, [keyword])

  useEffect(() => {
    dispatch(fetchAllCategories())
  }, [dispatch])

  let {loading, categories} = useSelector((state) => state.categories)
  let {theme} = useSelector((state) => state.theme)
  let items = categories?.map((d, i) => {
    return <FilterButton setKeyword={setKeyword} key={i} tag={d.slug?.current} title={d.title} />
  })

  return (
    <nav className="flex sm:visible hidden justify-center z-20 p-3 relative w-full ">
      <FilterButton setKeyword={setKeyword} key={'all'} title="All" />
      {items}
    </nav>
  )
}
