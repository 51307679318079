import React from 'react'
import {GridTiles, ProjectTiles, FilterNav} from '../components'

export const PortfolioPage = () => {
  return (
    <>
      <div className="h-full content-baseline flex flex-col flex-wrap w-full justify-start overflow-hidden ">
        <FilterNav />
        <GridTiles className="sm:pr-10 sm:p-5 flex-1 overflow-auto scrollbar scrollbarStyle h-full" />
        {/* <ProjectTiles /> */}
      </div>
    </>
  )
}
