import clsx from 'clsx'
import React from 'react'
import {useSelector} from 'react-redux'

export const Ghost = ({loading = true, className, children}) => {
  if (loading) {
    return (
      <span
        className={clsx(
          'mt-1 ml-auto h-[1em] block dark:bg-gray-700 bg-gray-300 animate-pulse',
          className
        )}
      ></span>
    )
  } else {
    return children
  }
}
