import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

let sanityCnfg = {
  projectId: 'iffox0g0',
  dataset: 'production',
  apiVersion: '2021-08-31',
  // token: 'sanity-auth-token',
  useCdn: true,
}

const mySanity = sanityClient(sanityCnfg)

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient)

export const urlFor = (source: any) => imageUrlBuilder(sanityCnfg).image(source)
export default mySanity
