import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiMoon, FiSun, FiUser, FiHome, FiGrid} from 'react-icons/fi'
import logo from '../assets/logo.png'
import logoDark from '../assets/logo_dark.png'
import {Link} from 'react-router-dom'
import clsx from 'clsx'

export const Header = () => {
  let dispatch = useDispatch()

  let {theme} = useSelector((state) => state.theme)
  let themeHandler = () => {
    if (theme === 'dark') {
      dispatch({type: 'SET_LIGHT_THEME'})
      window.localStorage.setItem('darkTheme', '0')
    } else {
      window.localStorage.setItem('darkTheme', '1')
      dispatch({type: 'SET_DARK_THEME'})
    }
  }
  return (
    <nav
      className="relative flex sm:flex-col flex-row z-50 
      justify-between items-center p-5 sm:w-[80px]
      w-full max-h-[80px] sm:min-h-full"
    >
      <div
        className={clsx(
          'divider h-screen -translate-y-[50%] w-[80px] sm:-rotate-[180deg] rotate-[-90deg] sm:top-[50%] top-[120px] sm:left-[80px] left-[30%]',
          theme === 'dark' && 'dark'
        )}
      />
      <img alt="logo" className="w-12" src={theme === 'dark' ? logoDark : logo} />
      <ul className="sm:space-y-3 space-y-0 sm:space-x-0 space-x-3 items-center flex flex-row sm:flex-col">
        <li>
          <Link to="/">
            <button className="button">
              <FiHome />
            </button>
          </Link>
        </li>
        <li>
          <Link to="/portfolio">
            <button className="button">
              <FiGrid />
            </button>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <button className="button">
              <FiUser />
            </button>
          </Link>
        </li>
      </ul>
      <button className="button" onClick={themeHandler}>
        {theme === 'dark' ? <FiSun /> : <FiMoon />}
      </button>
    </nav>
  )
}
