import React from 'react'
import clsx from 'clsx'
import {useSelector} from 'react-redux'

function rndmNum(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const Wobble = ({className, amount = 5, screenMax = 0}) => {
  let {theme} = useSelector((state) => state.theme)
  let width = ((screenMax - 40) * Math.round(100 / amount)) / 100

  let Wobble = ({width, even}) => {
    width = width - rndmNum(0, 80)
    let t = `calc(50% - ${width}px / 2)`
    let l = `calc(50% - ${width}px / 2)`
    let direct = even ? 'reverse' : 'normal'
    return (
      <svg
        width={width > 0 ? width : '0'}
        height={width > 0 ? width : '0'}
        viewBox="0 0 467 444"
        fill="none"
        style={{
          animationDirection: direct,
          animationName: 'spin',
          animationDuration: `${rndmNum(8, 15)}s`,
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          top: t,
          left: l,
        }}
        className={clsx(className, 'absolute z-0 ')}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.2656 383.998C174.509 471.528 308.364 456.991 396.053 367.587C483.718 278.207 491.784 148.089 402.52 60.5376C357.908 16.7821 292.942 -1.98749 228.099 1.39173C163.256 4.77093 98.672 30.2942 54.8783 74.9447C-32.5771 164.111 -4.23045 296.219 85.2656 383.998ZM84.5654 384.712C174.281 472.706 308.773 458.003 396.767 368.287C484.761 278.572 492.935 147.818 403.22 59.8237C313.504 -28.1703 142.158 -15.4709 54.1644 74.2444C-33.8296 163.96 -5.14989 296.718 84.5654 384.712Z"
          fill={theme === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.08)'}
        />
      </svg>
    )
  }

  let wobbles = []
  for (let i = 0; i < amount + 1; i++) {
    wobbles.push(<Wobble width={width * i} even={i % 2} key={i} />)
  }
  return screenMax ? <div className='absolute w-full h-full overflow-hidden'>{wobbles}</div> : <div></div>
}
