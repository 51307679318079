import React, {useEffect, useState} from 'react'
import {VictoryPie, VictoryLabel} from 'victory'
import {FiFigma, FiHelpCircle, FiPenTool} from 'react-icons/fi'
import {
  SiAdobephotoshop,
  SiReact,
  SiAdobeillustrator,
  SiAdobeindesign,
  SiAdobeaftereffects,
  SiHtml5,
} from 'react-icons/si'
import {ImWordpress} from 'react-icons/im'
import {fetchAllSkills} from '../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {Spinner} from './spinner'
import clsx from 'clsx'

let FAKE = [
  {
    title: '',
    skill: 0,
  },
  {
    title: '',
    skill: 0,
  },
  {
    title: '',
    skill: 0,
  },
  {
    title: '',
    skill: 0,
  },
  {
    title: '',
    skill: 0,
  },
]

export const SkillGraph = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllSkills())
  }, [dispatch])
  let {loading, skills} = useSelector((state) => state.skills)
  skills = skills || FAKE

  let item = skills?.map((e, i) => {
    let newData = [
      {x: e.title, y: e.skill},
      {x: 'Not', y: 100 - e.skill},
    ]
    return (
      <React.Fragment key={i}>
        <Graph
          loading={loading}
          className={clsx(loading && 'animate-pulse')}
          title={e.title}
          graphData={newData}
          icon={e.icon || e.title}
        ></Graph>
      </React.Fragment>
    )
  })

  return <>{item}</>
}

let defaultData = [
  {x: 'Skill', y: 0},
  {x: 'Not', y: 100},
]

let iconMap = {
  figma: <FiFigma />,
  photoshop: <SiAdobephotoshop />,
  react: <SiReact />,
  illustrator: <SiAdobeillustrator />,
  indesign: <SiAdobeindesign />,
  aftereffects: <SiAdobeaftereffects />,
  wordpress: <ImWordpress />,
  html: <SiHtml5 />,
}

const Graph = ({className, graphData, loading = true, title, icon, size = 260}) => {
  const [graphicData, setGraphicData] = useState(defaultData)
  let {theme} = useSelector((state) => state.theme)
  useEffect(() => {
    setGraphicData(graphData)
  }, [graphData])

  let content = (
    <>
      <span
        className={clsx(
          className,
          'dark:text-gray-400 text-xl w-40 items-center flex flex-col absolute left-[0%] top-[35%]'
        )}
      >
        <span className={clsx('mb-1', loading && 'text-gray-600')}>
          {iconMap[icon.toLowerCase()] || <FiHelpCircle />}
        </span>
        {loading ? (
          <span className="w-14 h-3 mt-1 bg-gray-600"></span>
        ) : (
          <span className="text-sm tracking-wider">{title}</span>
        )}
      </span>
      <svg viewBox={`0 0 ${size} ${size}`}>
        <defs>
          <linearGradient id="myGradient" x1="130%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#3DD0FF" />
            <stop offset="100%" stopColor="#FF60DC" />
            <stop offset="100%" stopColor="#B3FF11" />
          </linearGradient>
        </defs>
        <VictoryPie
          className={clsx(className)}
          standalone={false}
          animate={{duration: 1000, easing: 'bounce'}}
          width={size}
          height={size}
          data={graphicData}
          // cornerRadius={20}
          labels={() => null}
          innerRadius={72}
          // labelRadius={100}
          // labelComponent={<FiFigma />}
          colorScale={[
            'url(#myGradient)',
            theme === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
          ]}
        />

        <VictoryLabel
          textAnchor="middle"
          style={{fontSize: 46, textTransform: 'uppercase'}}
          x={200}
          y={200}
        />
      </svg>
    </>
  )
  return <div className="relative h-40 w-40">{content}</div>
}
