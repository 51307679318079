import {combineReducers} from 'redux'
import {
  fetchAllProjectsReducer as projects,
  fetchAllSkillsReducer as skills,
  themeReducer as theme,
  fetchAllCategoriesReducer as categories,
} from './reducers'

// import {fetchAllPersonsReducer, fetchPersonByIdReducer, countPersonsReducer} from './personReducers'

// import {toggleTheme} from './globalReducers'

export default combineReducers({
  projects,
  theme,
  skills,
  categories,
})
