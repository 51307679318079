import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {urlFor} from '../redux/sanitySetup.js'
import '../index.css'
import {Ghost} from './ghost'
import clsx from 'clsx'
import {wrapGrid} from 'animate-css-grid'
import spot from '../assets/light_source.png'
import {FiX} from 'react-icons/fi'
import {MouseParallaxContainer} from 'react-parallax-mouse'

// let FAKE = [
//   {
//     name: 'test',
//     subtitle: 'irgendwo',
//     color: {rgb: {r: '20', g: '20', b: '20'}},
//     categories: [{title: 'product'}, {title: 'product'}],
//   },
//   {
//     name: 'sowas',
//     subtitle: 'nirgendwo',
//     color: {rgb: {r: '34', g: '85', b: '52'}, hex: '#225534'},
//     categories: [{title: 'product'}, {title: 'product'}],
//   },
//   {
//     name: 'test',
//     subtitle: 'irgendwo',
//     color: {rgb: {r: '20', g: '20', b: '20'}},
//     categories: [{title: 'product'}, {title: 'product'}],
//   },
//   {
//     name: 'sowas',
//     featured: true,
//     subtitle: 'nirgendwo',
//     color: {rgb: {r: '34', g: '85', b: '52'}, hex: '#225534'},
//     categories: [{title: 'product'}, {title: 'product'}],
//   },
// ]
let FAKE = [
  {
    name: '',
    subtitle: '',
    color: {rgb: {r: '0', g: '0', b: '0'}},
    categories: [{title: ''}],
  },
  {
    name: '',
    subtitle: '',
    color: {rgb: {r: '0', g: '0', b: '0'}},
    categories: [{title: ''}],
  },
  {
    name: '',
    subtitle: '',
    color: {rgb: {r: '0', g: '0', b: '0'}},
    categories: [{title: ''}],
  },
  {
    name: '',
    subtitle: '',
    color: {rgb: {r: '0', g: '0', b: '0'}},
    categories: [{title: ''}],
  },
  {
    name: '',
    subtitle: '',
    color: {rgb: {r: '0', g: '0', b: '0'}},
    categories: [{title: ''}],
  },
  {
    name: '',
    subtitle: '',
    color: {rgb: {r: '0', g: '0', b: '0'}},
    categories: [{title: ''}],
  },
]

export const GridTiles = ({className}) => {
  useEffect(() => {
    const grid = document.querySelector('.grid')
    wrapGrid(grid, {
      duration: 600,
      stagger: 0,
      easing: 'anticipate',
    })
  }, [])

  return (
    <div className={clsx(className, 'grid snap-y snap-proximity')}>
      <ProjectTiles />
    </div>
  )
}

export const ProjectTiles = ({}) => {
  let [active, setActive] = useState(null)

  const openHandle = (e, i) => {
    active !== i && setActive(i)
  }
  let nextHandle = (i) => {
    setActive(i + 1)
  }
  let prevHandle = (i) => {
    setActive(i - 1)
  }
  let closeHandle = (i) => {
    setActive(null)
  }

  let {loading, projects} = useSelector((state) => state.projects)
  let items = []
  projects = projects || FAKE

  items = projects?.map((d, i) => {
    let catLength = d.categories.length
    let categories = d.categories.map((e, i) => {
      if (i < catLength - 1) {
        return <span key={i}>{e.title} • </span>
      } else {
        return e.title
      }
    })
    // let clickClass = null
    // if (active === i) {
    //   clickClass = 'active'
    // }
    let handler = {
      next: () => nextHandle(i),
      prev: () => prevHandle(i),
      close: () => closeHandle(i),
      open: (e) => openHandle(e, i),
    }

    return (
      <Tile
        loading={loading}
        key={i}
        active={active}
        categories={categories}
        data={d}
        item={i}
        handler={handler}
      />
    )
  })

  return <>{items}</>
}

const getDimensionsByUrl = (url) => {
  if (url) {
    let width = url.substring(url.indexOf('-') + 1, url.lastIndexOf('x'))
    let height = url.substring(url.indexOf('-') + width.length + 2, url.lastIndexOf('.'))
    width = Number(width)
    height = Number(height)
    return {width, height}
  } else {
    return {width: 0, height: 0}
  }
}

function blocksToText(blocks) {
  return blocks?.map((block) => block.children.map((child) => child.text).join(''))
}

const Tile = ({loading, item, active, handler, data, categories, classNames}) => {
  let {theme} = useSelector((state) => state.theme)
  // let act = active == item || false
  active = item === active ? true : false

  let {poster, name, client, disabled, subtitle, featured, color, description, year} = data
  description = blocksToText(description)
  let {r, g, b} = color.rgb
  let posterUrl
  poster && (posterUrl = urlFor(poster).url())
  let {width = 0, height = 0} = getDimensionsByUrl(posterUrl)
  let imgHeight = 220
  if (width > height) {
    imgHeight = 120
  }
  let imgStyle = {height: `${imgHeight}px`}
  let txtShdwC = theme === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'
  let txtShdw = `-1px -1px 1px ${txtShdwC}, 1px -1px 1px ${txtShdwC}, -1px 1px 1px ${txtShdwC}, 1px 1px 1px ${txtShdwC}`
  return (
    <div
      // onClick={handler.open}
      className={clsx(
        'tile',
        featured && 'featured',
        disabled && 'disabled',
        active ? 'active overflow-hidden sm:snap-center snap-top' : 'overflow-visible snap-none',
        disabled && 'disabled'
      )}
    >
      <div className="relative h-full">
        <div className="bg" />
        {loading ? (
          <Ghost className={'absolute poster h-1/2 w-1/5 left-14 bottom-1/3'}></Ghost>
        ) : (
          <img
            alt="background"
            className={clsx(
              'poster transition-all duration-700 bottom-[calc(50%-100px)] z-20 absolute',
              active
                ? 'transition-all duration-700 left-[calc(50%-95px)] scale-150 '
                : 'transition-all duration-700 left-8 scale-100 opacity-100',
              theme === 'dark ' && 'dark',
              // featured && 'left-[calc(50%)] bottom-[20%] -translate-x-[50%]',
              featured && active && 'scale-100 left-[calc(38%)]'
            )}
            style={imgStyle}
            src={posterUrl}
          />
        )}
        <div
          style={{background: `linear-gradient( rgba(${r},${g},${b},0.3), rgba(80,80,80,0))`}}
          className={clsx(
            'circle',
            active
              ? 'right-[calc(50%-450px)] top-[calc(50%-520px)] h-[900px] w-[900px] opacity-100'
              : 'right-[calc(50%-50px)] top-[calc(50%-50px)] h-[0px] w-[0px] opacity-0'
          )}
        ></div>
        <div
          style={{
            background: `radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 35%)`,
          }}
          className={clsx(
            'absolute transition-all duration-500;',
            active
              ? 'top-[calc(50%+30px)] h-[300px] w-full opacity-100'
              : 'right-[calc(50%-50px)] top-[calc(50%-50px)] h-[0px] w-[0px] opacity-0'
          )}
        ></div>

        {/* <img src={spot} className={clsx('spot', active ? 'opacity-100 delay-500' : 'opacity-0')} /> */}
        <div
          className={clsx(
            active ? 'text-center' : 'text-right',
            'info transition-all duration-700'
          )}
        >
          <div className={clsx('header z-20', featured && 'text-center')}>
            <h2 style={{textShadow: txtShdw}} className="mb-3">
              <Ghost loading={loading} className={'w-40'}>
                {subtitle}
              </Ghost>
            </h2>
            <h1
              className="uppercase"
              style={{
                color: color.hex,
                textShadow: txtShdw,
              }}
            >
              <Ghost loading={loading} className={'w-52'}>
                {name}
              </Ghost>
            </h1>
          </div>
          <div className="dot" style={{background: `rgba(${r - 5},${g - 5},${b - 5},0.32)`}} />
          {/* <div className="dot2" style={{background: `rgba(${r},${g},${b},0.2)`}} /> */}
          {active && (
            <>
              <div className="text-sm z-30 absolute r-6 top-0">
                <button className="button" onClick={handler.close}>
                  <FiX />
                </button>
              </div>
              <div className="flex flex-row justify-between text-left">
                <div className="text-sm "></div>
                <div className="text-sm"></div>
                <div className="text-sm w-1/4">
                  {/* {client} */}
                  {description}
                </div>
              </div>
            </>
          )}
          <p className="z-10 w-full font-light dark:text-gray-400">
            <Ghost loading={loading}>{categories}</Ghost>
          </p>
        </div>
      </div>
    </div>
  )
}
