import sanityAPI from './sanitySetup'
import {
  PROJECTS_FETCH_FAIL,
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  SKILLS_FETCH_FAIL,
  SKILLS_FETCH_REQUEST,
  SKILLS_FETCH_SUCCESS,
  CATEGORIES_FETCH_FAIL,
  CATEGORIES_FETCH_REQUEST,
  CATEGORIES_FETCH_SUCCESS,
} from './constants/projectsConstants'

export const fetchAllProjects = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: PROJECTS_FETCH_REQUEST,
    })

    const data = await sanityAPI.fetch(
      // `*[_type == 'portfolio']{_id, name, featured, subtitle, color, poster, categories[]->{title, _id}}`
      `*[_type == "portfolio"${
        keyword ? ` && "${keyword}" in categories[]->slug.current` : ''
      }] | order(year desc) {
        ...,
        categories[] -> {
                title,
        },
      }
      `
    )
    dispatch({
      type: PROJECTS_FETCH_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECTS_FETCH_FAIL,
      payload: error.message,
    })
  }
}

export const fetchAllSkills = () => async (dispatch) => {
  try {
    dispatch({
      type: SKILLS_FETCH_REQUEST,
    })
    const data = await sanityAPI.fetch(`*[_type == 'skills'] {...}`)
    dispatch({
      type: SKILLS_FETCH_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SKILLS_FETCH_FAIL,
      payload: error.message,
    })
  }
}
export const fetchAllCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORIES_FETCH_REQUEST,
    })
    const data = await sanityAPI.fetch(`*[_type == 'category'] | order(title asc) {...}`)
    dispatch({
      type: CATEGORIES_FETCH_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_FETCH_FAIL,
      payload: error.message,
    })
  }
}
