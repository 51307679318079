import sanityAPI from '../sanitySetup'
import {
  PROJECTS_FETCH_FAIL,
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  SKILLS_FETCH_FAIL,
  SKILLS_FETCH_REQUEST,
  SKILLS_FETCH_SUCCESS,
  CATEGORIES_FETCH_FAIL,
  CATEGORIES_FETCH_REQUEST,
  CATEGORIES_FETCH_SUCCESS,
} from '../constants/projectsConstants'
import {SET_LIGHT_THEME, SET_DARK_THEME} from '../constants/globalConstants'

const fetchAllProjectsReducer = (state = {}, action) => {
  switch (action.type) {
    case PROJECTS_FETCH_REQUEST:
      return {
        loading: true,
      }
    case PROJECTS_FETCH_SUCCESS:
      return {
        loading: false,
        projects: action.payload,
      }
    case PROJECTS_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
const fetchAllSkillsReducer = (state = {}, action) => {
  switch (action.type) {
    case SKILLS_FETCH_REQUEST:
      return {
        loading: true,
      }
    case SKILLS_FETCH_SUCCESS:
      return {
        loading: false,
        skills: action.payload,
      }
    case SKILLS_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
const fetchAllCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_FETCH_REQUEST:
      return {
        loading: true,
      }
    case CATEGORIES_FETCH_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
      }
    case CATEGORIES_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

let defaultTheme = window.localStorage.getItem('darkTheme') === '0' ? 'light' : 'dark'

const themeReducer = (state = {theme: defaultTheme || 'dark'}, action) => {
  switch (action.type) {
    case SET_LIGHT_THEME:
      return {
        theme: 'light',
      }
    case SET_DARK_THEME:
      return {
        theme: 'dark',
      }
    default:
      return state
  }
}

export {fetchAllProjectsReducer, themeReducer, fetchAllSkillsReducer, fetchAllCategoriesReducer}
