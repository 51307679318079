import React from 'react'
import {useSelector} from 'react-redux'
import logo from '../assets/logo.png'
import logoDark from '../assets/logo_dark.png'

export const HomePage = () => {
  let {theme} = useSelector((state) => state.theme)
  let imgW = 180
  let t = `calc(50% - ${imgW}px / 2)`
  let l = `calc(50% - ${imgW}px / 2)`
  let w = `${imgW}px`

  return (
    <div className="w-full h-full">
      {' '}
      <img
        alt="logo"
        src={theme === 'dark' ? logoDark : logo}
        style={{height: w, top: t, left: l}}
        className="absolute"
      />
    </div>
  )
}
