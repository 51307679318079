import React, {useState, useEffect, useRef} from 'react'
import {Header, Wobble} from './components'
import {AboutPage, HomePage, PortfolioPage} from './pages'
import {useSelector} from 'react-redux'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import bg from './assets/bg.png'

// function MyComponent() {
//   React.useEffect(() => {
//     window.addEventListener('resize', handleResize)
//   })
//   return <div>w00t!</div>
// }

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

function App() {
  let ref = useRef()
  let {theme} = useSelector((state) => state.theme)
  let [resize, setResize] = useState(0)
  let [max, setMax] = useState(undefined)

  useEffect(() => {
    let h = ref.current?.clientHeight
    let w = ref.current?.clientWidth

    if (h > w) {
      setMax(h)
    } else {
      setMax(w)
    }
    const debouncedHandleResize = debounce(function handleResize() {
      setResize(resize + 1)
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [resize])

  return (
    <BrowserRouter>
      <div ref={ref} className={theme}>
        <div
          // style={{
          //   backgroundImage: `url(${bg})`,
          //   backgroundSize: 'cover',
          // }}
          className=" bg-bg dark:bg-darkBg h-screen w-screen scrollbar scrollbarStyle flex sm:flex-row flex-col"
        >
          <Header />
          <main className="relative sm:scrollbar sm:scrollbarStyle w-full sm:h-full h-[calc(100%-80px)]">
            <Wobble amount={4} screenMax={max} className="m-auto" />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/portfolio" element={<PortfolioPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </main>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App

/* <Route path="/" exact> */
