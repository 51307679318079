export const PROJECT_FETCH_REQUEST = 'PROJECT_FETCH_REQUEST'
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS'
export const PROJECT_FETCH_FAIL = 'PROJECT_FETCH_FAIL'

export const PROJECTS_FETCH_FAIL = 'PROJECTS_FETCH_FAIL'
export const PROJECTS_FETCH_REQUEST = 'PROJECTS_FETCH_REQUEST'
export const PROJECTS_FETCH_SUCCESS = 'PROJECTS_FETCH_SUCCESS'
// export const PROJECTS_FETCH_RESET = "PROJECTS_FETCH_RESET";
export const SKILLS_FETCH_REQUEST = 'PROJECT_FETCH_REQUEST'
export const SKILLS_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS'
export const SKILLS_FETCH_FAIL = 'PROJECT_FETCH_FAIL'

export const CATEGORIES_FETCH_REQUEST = 'PROJECT_FETCH_REQUEST'
export const CATEGORIES_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS'
export const CATEGORIES_FETCH_FAIL = 'PROJECT_FETCH_FAIL'
